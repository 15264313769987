const isFetchingSelector = ({ galleryState }) => galleryState.isFetching
const gallerySelector = ({ galleryState }) => galleryState.gallery
const currentImageSelector = ({ galleryState }) => galleryState.currentImage
const galleryPicturesSelector = ({ galleryState }) => galleryState.pictures
export {
  isFetchingSelector,
  gallerySelector,
  currentImageSelector,
  galleryPicturesSelector
}
