import React from 'react'
import { Fade, Button, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import 'components/Slider/Slider.css'
import MyLink from 'components/MyLink'

const materialStyles = {
    button: {
        borderColor: '#FFFFFF',
        color: '#FFFFFF'
    },
    arrowIcons: {
        fontSize: '4em'
    }
}

const Slider = withStyles(materialStyles)(
    class extends React.Component {

        constructor(props) {
            super(props)
            this.state = { currentPictureIndex: 0, sliderWidth: 0, timeoutResult: null }
            this.slider = React.createRef()
            window.addEventListener('resize', this.onResize)
        }

        componentDidMount() {
            this.setState({ timeoutResult: this.triggerTimeout(), sliderWidth: this.slider.current.getBoundingClientRect().width })
        }

        componentWillUnmount() {
            const { timeoutResult } = this.state
            if (timeoutResult != null) {
                clearTimeout(timeoutResult)
            }
            window.removeEventListener('resize', this.onResize)
        }

        onResize = () => {
            this.setState({ sliderWidth: this.slider.current.getBoundingClientRect().width })
        }

        resetTimeout = () => {
            const { timeoutResult } = this.state
            if (timeoutResult != null) {
                clearTimeout(timeoutResult)
            }
            this.setState({ timeoutResult: this.triggerTimeout() })
        }

        triggerTimeout = () => setTimeout(() => {
            const { currentPictureIndex } = this.state
            currentPictureIndex === this.props.pictures.length - 1
                ? this.setState({ currentPictureIndex: 0 })
                : this.setState({ currentPictureIndex: currentPictureIndex + 1 })
            this.resetTimeout()
        }, 8000)

        onClickNext = () => {
            const { currentPictureIndex } = this.state
            currentPictureIndex === this.props.pictures.length - 1
                ? this.setState({ currentPictureIndex: 0 })
                : this.setState({ currentPictureIndex: currentPictureIndex + 1 })
            this.resetTimeout()
        }

        onClickPrevious = () => {
            const { currentPictureIndex } = this.state
            currentPictureIndex === 0
                ? this.setState({ currentPictureIndex: this.props.pictures.length - 1 })
                : this.setState({ currentPictureIndex: currentPictureIndex - 1 })
            this.resetTimeout()
        }

        render() {
            const { sliderWidth, currentPictureIndex } = this.state
            return (
                <div className="slider" ref={this.slider} >
                    <div className="arrow-left arrow clickable" onClick={() => this.onClickPrevious()}>
                        <Icon className={this.props.classes.arrowIcons}>keyboard_arrow_left</Icon>
                    </div>
                    <div className="arrow-right arrow clickable" onClick={() => this.onClickNext()}>
                        <Icon className={this.props.classes.arrowIcons}>keyboard_arrow_right</Icon>
                    </div>
                    <div className="slider-wrapper" style={{
                        transform: `translateX(-${sliderWidth * currentPictureIndex}px)`,
                        transition: 'transform ease-out 0.8s'
                    }}>
                        {this.props.pictures.map((picture, index) => <Slide picture={picture} selected={index === currentPictureIndex} key={index} />)}
                    </div>
                </div>
            )

        }
    }
)

const Slide = withStyles(materialStyles)(
    function ({ picture, selected, classes }) {
        const styles = {
            backgroundImage: `url(${picture.src})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 60%',
        }

        return (
            <div className="slide" style={styles}>
                <Fade in={selected} timeout={{ enter: 3000, exit: 1000 }}>
                    <div className="slide-infos">
                        <h1 className="slide-title">
                            {picture.name}
                        </h1>
                        <MyLink to={picture.link}>
                            <Button className={classes.button} variant="outlined">Voir la galerie</Button>
                        </MyLink>
                    </div>
                </Fade>
            </div>
        )
    }
)

export default Slider