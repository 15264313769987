import Http from 'utils/Http'

const types = {
    SET_CAROUSEL_GALLERIES: 'SET_CAROUSEL_GALLERIES'
}

function setCarouselGalleries(galleries) {
    return {
        type: types.SET_CAROUSEL_GALLERIES,
        galleries
    }
}

const getCarouselGalleries = () => {
    return function (dispatch) {
        return Http.get('/carouselgalleries')
            .then(data => dispatch(setCarouselGalleries(data.galleries)))
    }
}

export {
    types,
    setCarouselGalleries,
    getCarouselGalleries
}