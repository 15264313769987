import { types } from './actions'

export default function adminMessageReducer(state = {}, action) {
    switch (action.type) {
        case types.SET_MESSAGE:
            return { ...state, messsage: action.message }
        default:
            return state
    }
}
