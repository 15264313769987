import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000'
        },
    },
    typography: {
        fontFamily: 'coolvetica'
    }
})

export default theme