import React, { useState } from 'react';
import { Dialog, DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';

function Modal(props) {
  const [isOpen, setIsOpen] = useState(false)
  const cancel = () => setIsOpen(false)
  const open = () => setIsOpen(true)
  const onValidate = () => {
    props.onValidate()
    cancel()
  }

  return (
    <div>
      {props.getButton(open)}
      <Dialog
        fullWidth={true}
        open={isOpen}
        onClose={() => cancel()}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onValidate()}>Valider</Button>
          <Button onClick={() => cancel()}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Modal;
