import Http from 'utils/Http'
import { setMessage } from 'redux/admin-redux/admin-message-redux/actions'


const updateDB = () => {
  return function (dispatch) {
    Http.get('/updatedb')
      .then(
        response => response.json(),
        error => console.log('An error occured.', error)
      )
      .then(
        json => {
          dispatch(setMessage(json.update))
        }
      )
  }
}

export {
  updateDB
}