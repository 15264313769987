import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles({
    progressBar: {
        height: '4px'
    }
})

function ProgressBar({ value, completeValue }) {
    const { progressBar } = useStyles()
    const progressPercentage = completeValue === 0 ? 0 : value / completeValue * 100
    return (
        <div className={progressBar}>
            {completeValue !== 0 && <LinearProgress variant="determinate" value={progressPercentage} />}
        </div>
    )
}

export default ProgressBar