const types = {
    SET_PROGRESS_UPLOAD: 'SET_PROGRESS_UPLOAD',
    SET_UPLOAD_SIZE: 'SET_UPLOAD_SIZE'
}

const setProgressUpload = progressValue => {
    return {
        type: types.SET_PROGRESS_UPLOAD,
        progressValue
    }
}

const setUploadSize = uploadSizeValue => {
    return {
        type: types.SET_UPLOAD_SIZE,
        uploadSizeValue
    }
}

export {
    types,
    setProgressUpload,
    setUploadSize
}