import { types } from './actions'

export default function adminProgressUploadReducer(state = {
    progressValue: 0,
    uploadSizeValue: 0
}, action) {
    switch (action.type) {
        case types.SET_PROGRESS_UPLOAD:
            return { ...state, progressValue: action.progressValue }
        case types.SET_UPLOAD_SIZE:
            return { ...state, uploadSizeValue: action.uploadSizeValue }
        default:
            return state
    }
}
