import { types } from './actions'

export default function adminGalleriesListReducer(state = {}, action) {
    switch (action.type) {
        case types.SET_GALLERIES_LISTS:
            return { ...state, galleriesLists: action.galleriesLists }
        case types.SET_CURRENT_GALLERIES_LIST:
            return { ...state, currentGalleriesList: action.galleriesList }
        default:
            return state
    }
}