import Http from 'utils/Http'
import { setProgressUpload, setUploadSize } from 'redux/admin-redux/admin-progress-upload/actions'

const types = {
    SET_GALLERIES: 'SET_GALLERIES',
    SET_CURRENT_GALLERY: 'SET_CURRENT_GALLERY',
}

const setGalleries = galleries => {
    return {
        type: types.SET_GALLERIES,
        galleries
    }
}

const setCurrentGallery = gallery => {
    return {
        type: types.SET_CURRENT_GALLERY,
        gallery
    }
}

const getGalleries = () => {
    return function (dispatch) {
        return Http.get('/galleries')
            .then(data => {
                dispatch(setGalleries(data.galleries))
            })
    }
}

const deletePicture = pictureId => {
    return function (dispatch) {
        return Http.delete(`/picture/${pictureId}`)
            .then(data => dispatch(setCurrentGallery(data.gallery)))
    }
}

const postGallery = (body) => {
    return function (dispatch) {
        const onProgress = event => {
            dispatch(setProgressUpload(event.loaded))
            dispatch(setUploadSize(event.total))
        }
        const formData = new FormData()
        formData.append('name', body.name)
        formData.append('description', body.description)
        Array.from(body.files).forEach((file, index) => formData.append(index, file))
        return Http.post(`/gallery`, formData, null, onProgress)
            .then(data => {
                dispatch(setProgressUpload(0))
                dispatch(setUploadSize(0))
                dispatch(setGalleries(data.galleries))
                return data
            })
    }
}

const putGallery = (galleryId, body) =>
    function (dispatch) {
        return Http.put(`/gallery/${galleryId}`, body)
            .then(data => {
                return data
            })
            .then(data => dispatch(setCurrentGallery(data.gallery)))
    }

const deleteGallery = (galleryId) =>
    function (dispatch) {
        return Http.delete(`/gallery/${galleryId}`)
            .then(data => dispatch(setGalleries(data.galleries)))
    }

const setPictureGalleryPreview = pictureId => {
    return function (dispatch) {
        return Http.put(`/picture/${pictureId}`, { galleryPreview: true })
            .then(data => dispatch(setCurrentGallery(data.gallery)))
    }
}

const addPictures = (galleryId, files) => {
    return function (dispatch) {
        const onProgress = event => {
            dispatch(setProgressUpload(event.loaded))
            dispatch(setUploadSize(event.total))
        }

        const formData = new FormData()
        formData.append("galleryId", galleryId)
        Array.from(files).forEach((file, index) => formData.append(index, file))

        return Http.post(`/picture`, formData, null, onProgress)
            .then(data => {
                dispatch(setProgressUpload(0))
                dispatch(setUploadSize(0))
                dispatch(setCurrentGallery(data.gallery))
                return data
            })
    }
}

export {
    types,
    setCurrentGallery,
    getGalleries,
    postGallery,
    deleteGallery,
    deletePicture,
    putGallery,
    setPictureGalleryPreview,
    addPictures
}
