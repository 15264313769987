import Http from 'utils/Http'
import { setProgressUpload, setUploadSize } from 'redux/admin-redux/admin-progress-upload/actions'

const types = {
    SET_GALLERIES_LISTS: 'SET_GALLERIES_LISTS',
    SET_CURRENT_GALLERIES_LIST: 'SET_CURRENT_GALLERIES_LIST',
}

const setGalleriesLists = galleriesLists => {
    return {
        type: types.SET_GALLERIES_LISTS,
        galleriesLists
    }
}

const setCurrentGalleriesList = galleriesList => {
    return {
        type: types.SET_CURRENT_GALLERIES_LIST,
        galleriesList
    }
}

const getGalleriesLists = () =>
    function (dispatch) {
        Http.get('/gallerieslists')
            .then(galleriesLists => dispatch(setGalleriesLists(galleriesLists)))
    }

const postGalleriesList = (body) => {
    return function (dispatch) {
        const onProgress = event => {
            dispatch(setProgressUpload(event.loaded))
            dispatch(setUploadSize(event.total))
        }
        const formData = new FormData()
        formData.append('name', body.name)
        formData.append('galleries', JSON.stringify(body.galleriesForms.map(galleryForm => galleryForm.name)))
        body.galleriesForms.forEach(galleryForm => {
            Array.from(galleryForm.files).forEach((galleryFiles, index) => formData.append(`${galleryForm.name}${index}`, galleryFiles))
        })
        return Http.post('/gallerieslist', formData, null, onProgress)
            .then(galleriesLists => {
                dispatch(setProgressUpload(0))
                dispatch(setUploadSize(0))
                dispatch(setGalleriesLists(galleriesLists))
                return galleriesLists
            })
    }
}

const putGalleriesList = (id, body) => {
    return function (dispatch) {
        return Http.put(`/gallerieslist/${id}`, body)
            .then(galleriesLists => dispatch(setGalleriesLists(galleriesLists)))
    }
}

const deleteGalleriesList = id => {
    return function (dispatch) {
        return Http.delete(`/gallerieslist/${id}`)
            .then(data => {
                dispatch(setCurrentGalleriesList(null))
                dispatch(setGalleriesLists(data.galleriesLists))
            })
    }
}

const addGalleryToList = (id, body) => {
    return function (dispatch) {
        return Http.put(`/gallerieslist/addGallery/${id}`, body)
            .then(galleriesList => dispatch(setCurrentGalleriesList(galleriesList)))
    }
}

const removeGalleryToList = (id, body) => {
    return function (dispatch) {
        return Http.put(`/gallerieslist/removeGallery/${id}`, body)
            .then(galleriesList => dispatch(setCurrentGalleriesList(galleriesList)))
    }
}

export {
    types,
    setGalleriesLists,
    setCurrentGalleriesList,
    getGalleriesLists,
    postGalleriesList,
    putGalleriesList,
    deleteGalleriesList,
    addGalleryToList,
    removeGalleryToList
}
