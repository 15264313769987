import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

function RichTextEditor({ setValue, value, fieldName }) {
    return (
        <div>
            {fieldName != null && <span>{fieldName}</span>}
            <ReactQuill value={value}
                onChange={(value) => setValue(value)} />
        </div>
    )
}

export default RichTextEditor