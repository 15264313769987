import React from 'react'
import { Icon as UIIcon, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    icon: props => {
        const color = props.color == null
            ? theme.palette.primary.main
            : theme.palette[props.color] == null
                ? props.color
                : theme.palette[props.color].main

        return {
            cursor: 'pointer',
            transition: 'color 0.5s',
            '&:hover': {
                transition: 'color 0.2s',
                color
            }
        }

    }
}))

function Icon(props) {
    const classes = useStyles(props)
    const onClickIcon = () => {
        props.onClick != null && props.onClick()
    }

    return (
        <UIIcon className={`${classes.icon} ${props.className}`} onClick={() => onClickIcon()}>{props.children}</UIIcon>
    )
}

export default Icon