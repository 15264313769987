import { types } from './actions'

export default function adminGalleryReducer(state = {}, action) {
    switch (action.type) {
        case types.SET_GALLERIES:
            return { ...state, galleries: action.galleries }
        case types.SET_CURRENT_GALLERY:
            const galleries = state.galleries.slice()
            const index = galleries.findIndex(gallery => gallery.id === action.gallery.id)
            galleries[index] = action.gallery
            return { ...state, currentGallery: action.gallery, galleries }
        default:
            return state
    }
}