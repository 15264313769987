import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    componentHidding: {
        opacity: '0 !important',
        transition: 'opacity 0.5s',
        height: '0px',
        overflow: 'hidden'
    },
    componentShowed: {
        opacity: '1',
        transition: 'opacity 0.5s'
    }
})

export default function Fade(props) {
    const [fadeEntrance, setFadeEntrance] = useState(false)

    useEffect(() => {
        setFadeEntrance(true)
    }, [])

    const { componentHidding, componentShowed } = useStyles()
    return (
        <div className={`${props.className} ${(props.show != null && props.show) || (props.show == null && fadeEntrance) ? componentShowed : componentHidding}`}>
            {props.children}
        </div>
    )

}