const types = {
    SET_MESSAGE: 'SET_MESSAGE'
}

const setMessage = message => {
    return {
        type: types.SET_MESSAGE,
        message
    }
}

export {
    types,
    setMessage,
}