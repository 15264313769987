import config from '../config'

const completeServerURL = config.addressServer

const queryStringBuild = (url, params = null) => {
    if (params == null) {
        return `${completeServerURL}${url}`
    }
    return `${completeServerURL}${url}?${Object.keys(params).map(paramKey => `${paramKey}=${params[paramKey]}`).join('&')}`
}

const setHeaders = () => {
    const userLoggedStr = sessionStorage.getItem('userLogged')

    const headers = {}
    if (userLoggedStr != null) {
        const userLogged = JSON.parse(userLoggedStr)
        headers['x-access-token'] = userLogged.token
    }
    return headers
}

class Http {


    static get = (url, params = null, onResponseReceived = 'json') => {
        return fetch(queryStringBuild(url, params), {
            method: 'GET',
            headers: setHeaders()
        })
            .then(response => response[onResponseReceived]())
    }

    static post = (url, body, params = null, onProgress = () => { }) => {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest()
            req.upload.addEventListener("progress", (event) => onProgress(event))
            req.onload = function (event) {
                resolve(req.response)
            }

            req.open("POST", queryStringBuild(url, params));
            req.responseType = 'json';
            Object.entries(setHeaders()).forEach(
                ([key, value]) => req.setRequestHeader(key, value)
            )
            req.send(body);
        })
    }

    static delete = (url, params = null) => {
        return fetch(queryStringBuild(url, params), {
            method: 'DELETE',
            headers: setHeaders()
        })
            .then(response => response.json())
    }

    static put = (url, body, params = null) => {
        return fetch(queryStringBuild(url, params), {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...setHeaders()
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
    }

}

export default Http