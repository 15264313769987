const currentGalleriesSelector = ({ navState }) => {
  return navState.currentGalleries
}

const navGalleriesSelector = ({ navState }) => {
  return navState.navGalleries
}

const currentGallerySelector = state => state.galleryState

const galleriesPageSelector = ({ navState }) => navState.galleriesPage


export {
  currentGalleriesSelector,
  currentGallerySelector,
  galleriesPageSelector,
  navGalleriesSelector
}
