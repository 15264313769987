import { types } from './actions'

export default function navReducer(state = { galleriesPage: 1 }, action) {
  switch (action.type) {
    case types.SET_CURRENT_GALLERIES:
      return { ...state, currentGalleries: action.galleries }
    case types.SET_GALLERIES_PAGE:
      return { ...state, galleriesPage: action.page }
    case types.SET_NAV_GALLERIES:
      return { ...state, navGalleries: action.galleries }
    default:
      return state
  }
}
