import Http from "utils/Http"

const types = {
    SET_USER_LOGGED: 'SET_USER_LOGGED'
}

function setUserLogged(user) {
    return {
        type: types.SET_USER_LOGGED,
        user
    }
}

function login(body) {
    return function (dispatch) {
        Http.put('/auth', body)
            .then(data => {
                if (data.user != null) {
                    dispatch(setUserLogged(data.user))
                    sessionStorage.setItem('userLogged', JSON.stringify(data.user))
                }
            })
    }
}

function checkAuth(body) {
    return function (dispatch) {
        Http.put('/checkauth', body)
            .then(data => {
                if (data.decoded != null) {
                    if (data.decoded._id === body.id) {
                        dispatch(setUserLogged(body))
                        refreshToken()(dispatch)
                    }
                }
            })
    }
}

function logout(body) {
    return function (dispatch) {
        Http.put('/logout', body)
            .then(data => {
                sessionStorage.removeItem('userLogged')
                dispatch(setUserLogged(null))
            })
    }
}

function refreshToken() {
    return function (dispatch) {
        const user = JSON.parse(sessionStorage.getItem('userLogged'))
        Http.put('/refresh', { user })
            .then(data => {
                if (data.token != null) {
                    user.token = data.token
                    sessionStorage.setItem('userLogged', JSON.stringify(user))
                    dispatch(setUserLogged(user))
                } else {
                    dispatch(setUserLogged(null))
                    sessionStorage.removeItem('userLogged')
                }
            })
    }
}

export {
    types,
    setUserLogged,
    login,
    checkAuth,
    logout,
    refreshToken
}