import React from 'react'
import { withStyles } from '@material-ui/styles'
import { List, ListItem, ListItemSecondaryAction, Divider } from '@material-ui/core'

import Icon from 'components/Icon'

import './EntitiesList.css'

const styles = {
    root: {
        width: '100%'
    },
}

function EntitiesList(props) {
    const { entities, classes, renderEntity, actions } = props
    return (
        <div className="entities-collection">
            {entities != null && <List className={classes.root}>
                {entities.map((entity, index) =>
                    <div key={index}>
                        <ListItem>
                            {renderEntity(entity)}
                            <ListItemSecondaryAction>
                                <div className="actions">
                                    {actions != null && actions.map(
                                        (action, index) =>
                                            <span key={index}>
                                                {(action.display == null || action.display(entity))
                                                    && <Icon
                                                        onClick={() => action.onClick(entity.id)}
                                                        color={action.color}
                                                    >{action.icon}</Icon>}
                                            </span>
                                    )}

                                </div>
                            </ListItemSecondaryAction>
                        </ListItem>
                        {index !== entities.length - 1 && <Divider />}
                    </div>
                )}
                {entities.length === 0 && <div className="empty-list-label-container">
                    <Icon>not_interested</Icon>
                    <span className="empty-list-label" >La liste est vide</span>
                </div>}
            </List>}
        </div>
    )
}

export default withStyles(styles)(EntitiesList)