import React from 'react'
import { List, ListItem, CircularProgress, ListItemSecondaryAction } from '@material-ui/core'
import Icon from 'components/Icon'

import './SelectableEntitiesList.css'

class SelectableEntitiesList extends React.Component {

    constructor(props) {
        super(props)
        this.state = { displayForm: false, editedEntity: null }
    }

    componentDidMount() {
        this.props.getEntities()
    }

    selectEntity(entity) {
        this.props.setCurrentEntity(entity)
    }

    displayForm = (editedEntity) => {
        this.setState({ displayForm: true, editedEntity })
    }

    deleteEntity = (entity) => {
        this.props.deleteEntity(entity)
    }

    closeForm = () => {
        this.setState({ displayForm: false, editedEntity: null })
    }

    getIcon = (entity, icon, onClick) => {
        if (typeof icon === 'string') {
            return <Icon onClick={() => onClick(entity)}>{icon}</Icon>
        } else {
            return icon(entity)
        }
    }

    render() {
        const { entities, displayEntity, selectedEntity, componentToDisplay, entityForm, actions } = this.props
        const { displayForm, editedEntity } = this.state
        return (
            <div className="list-container">
                <div className="entities-container">
                    <div className="add-icon">
                        <Icon
                            onClick={() => this.displayForm(null)}>add</Icon>

                    </div>
                    <div >
                        {entities == null &&
                            <div className="loader-container">
                                <CircularProgress color="primary" />
                            </div>
                        }
                        {entities != null &&
                            <List>
                                {entities.map((entity, index) => (
                                    <ListItem
                                        className="clickable"
                                        key={index}
                                        onClick={() => this.selectEntity(entity)}
                                        selected={selectedEntity != null && selectedEntity.id === entity.id}
                                    >
                                        {displayEntity(entity)}
                                        <ListItemSecondaryAction>
                                            {actions != null && actions.map(({ display, icon, onClick }, index) =>
                                                <span key={index}>
                                                    {(display == null || display(entity)) && this.getIcon(entity, icon, onClick)}
                                                </span>
                                            )}
                                            <Icon onClick={() => this.displayForm(entity)}>edit</Icon>
                                            <Icon color="error" onClick={() => this.deleteEntity(entity)}>delete</Icon>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        }
                    </div>
                </div>
                <div className="entity-container">
                    {selectedEntity != null && !displayForm && componentToDisplay(selectedEntity)}
                    {displayForm && entityForm(this.closeForm, editedEntity)}
                </div>
            </div>
        )
    }
}

export default SelectableEntitiesList