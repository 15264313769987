import { combineReducers } from 'redux'
import adminGalleryReducer from 'redux/admin-redux/admin-gallery-redux/reducer'
import adminGalleriesListReducer from 'redux/admin-redux/admin-galleries-list-redux/reducer'
import adminProgressUploadReducer from 'redux/admin-redux/admin-progress-upload/reducer'
import adminMessageReducer from 'redux/admin-redux/admin-message-redux/reducer'

const adminReducer = combineReducers(
  {
    adminGalleryState: adminGalleryReducer,
    adminGalleriesListState: adminGalleriesListReducer,
    adminProgressUploadState: adminProgressUploadReducer,
    adminMessageState: adminMessageReducer
  })

export default adminReducer
