import Http from 'utils/Http'

const types = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_GALLERIES_LIST_OBJECT: 'SET_GALLERIES_LIST_OBJECT',
}


const setIsFetching = bool => {
  return {
    type: types.SET_IS_FETCHING,
    bool
  }
}

const setGalleriesListObject = galleriesList => {
  return {
    type: types.SET_GALLERIES_LIST_OBJECT,
    galleriesList
  }
}

const fetchGalleriesList = (galleriesListId) => {
  return function (dispatch) {
    dispatch(setIsFetching(true))
    return Http.get(`/galleriesList/${galleriesListId}`)
      // .then(
      //   async galleriesList => {
      //     return {
      //       ...galleriesList,
      //       galleries: await Promise.all(
      //         galleriesList.galleries.map(
      //           gallery => Http.get(gallery.randPicture)
      //             .then(picture => picture.blob())
      //             .then(picture => {
      //               gallery.randPicture = URL.createObjectURL(picture)
      //               return gallery
      //             })
      //         )
      //       )
      //     }

      //   }
      // )
      .then(
        json => {
          dispatch(setGalleriesListObject(json))
          dispatch(setIsFetching(false))
        }
      )
  }
}

export { types, fetchGalleriesList, setGalleriesListObject }
