import { types } from './actions'

export default function galleriesListReducer(state = { isFetching: false }, action) {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.bool }
    case types.SET_GALLERIES_LIST_OBJECT:
      return { ...state, galleriesList: action.galleriesList }
    default:
      return state
  }
}
