import Http from 'utils/Http';

function sendEmail(form) {
  const formData = new FormData()
  Object.entries(form).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return Http.post('/sendemail', formData)
}

export { sendEmail }
