import Http from 'utils/Http'

import { number_of_pictures } from './constants'

const types = {
  ADD_GALLERY_PICTURES: 'ADD_GALLERY_PICTURES',
  SET_GALLERY: 'SET_GALLERY',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_CURRENT_PICTURE_INDEX: 'SET_CURRENT_PICTURE_INDEX',
  LOAD_MORE_PICTURES: 'LOAD_MORE_PICTURES'
}

function addGalleryPictures(pictures) {
  return {
    type: types.ADD_GALLERY_PICTURES,
    pictures
  }
}

function setGallery(gallery) {
  return {
    type: types.SET_GALLERY,
    gallery
  }
}

function setIsFetching(bool) {
  return {
    type: types.SET_IS_FETCHING,
    bool
  }
}

function setCurrentPictureIndex(id) {
  return {
    type: types.SET_CURRENT_PICTURE_INDEX,
    id
  }
}

function loadMorePictures() {
  return {
    type: types.LOAD_MORE_PICTURES
  }
}

const getPictures = galleryId =>
  galleryId != null
    ? fetchGallery(galleryId)
    : fetchPictures()

const fetchGallery = galleryId => {
  return function (dispatch) {
    dispatch(setIsFetching(true))
    return Http.get(`/gallery/${galleryId}`)
      .then(json => {
        return json
      })
      .then(gallery => {
        dispatch(setGallery({ ...gallery, pictures: [] }))
        dispatch(addGalleryPictures(gallery.pictures.slice(0, number_of_pictures)))
        return gallery
      })
      .then(gallery => {
        dispatch(setGallery(gallery))
        dispatch(setIsFetching(false))
      })
  }
}

const fetchPictures = () => {
  return function (dispatch) {
    dispatch(setIsFetching(true))
    return Http.get(`/pictures`)
      .then(json => {
        json.name = 'Toutes les photos'
        dispatch(setGallery(json))
        dispatch(addGalleryPictures(json.pictures.slice(0, number_of_pictures)))
        dispatch(setIsFetching(false))
      })
  }
}

export {
  types,
  addGalleryPictures,
  setGallery,
  loadMorePictures,
  setCurrentPictureIndex,
  getPictures
}
