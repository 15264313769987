import Http from 'utils/Http'

const types = {
  SET_CURRENT_GALLERIES: 'SET_CURRENT_GALLERIES',
  SET_GALLERIES_PAGE: 'SET_GALLERIES_PAGE',
  SET_NAV_GALLERIES: 'SET_NAV_GALLERIES'
}

function setGalleriesPage(page) {
  return {
    type: types.SET_GALLERIES_PAGE,
    page
  }
}

function setCurrentGalleries(galleries) {
  return {
    type: types.SET_CURRENT_GALLERIES,
    galleries
  }
}

function setNavGalleries(galleries) {
  return {
    type: types.SET_NAV_GALLERIES,
    galleries
  }
}

function fetchNavGalleries() {
  return dispatch => {
    Http.get(`/navgalleries`)
      .then(
        galleries => {
          dispatch(setNavGalleries(galleries))
        }
      )
  }
}

export {
  types,
  setCurrentGalleries,
  setGalleriesPage,
  fetchNavGalleries
}
