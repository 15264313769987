import React from 'react'
import {
    Link
} from 'react-router-dom'

import { withStyles } from '@material-ui/styles'

const styles = theme =>
    ({
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none'
        }
    })


function MyLink(props) {
    return (
        <Link  {...props} className={`${props.className} ${props.classes.link}`} >{props.children}</Link>
    )
}

export default withStyles(styles)(MyLink)