import { types } from './actions'
import { number_of_pictures } from './constants'

export default function galleryReducer(state = { pictures: [] }, action) {
  switch (action.type) {
    case types.SET_IS_FETCHING:
      return { ...state, isFetching: action.bool }
    case types.ADD_GALLERY_PICTURES:
      if (action.pictures == null) {
        return { ...state, pictures: [] }
      }
      const pictures = [...state.pictures, ...action.pictures]
      return { ...state, pictures }
    case types.LOAD_MORE_PICTURES:
      const loadMorePictures = [...state.pictures]
      const newPictures = state.gallery.pictures.slice(loadMorePictures.length, loadMorePictures.length + number_of_pictures)
      return { ...state, pictures: loadMorePictures.concat(newPictures) }
    case types.SET_GALLERY:
      return { ...state, gallery: action.gallery }
    case types.SET_CURRENT_PICTURE_INDEX:
      return { ...state, currentImage: action.id }
    default:
      return state
  }
}
